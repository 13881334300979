import React, { useState } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = () => {
  const [showNav, setShowNav] = useState(false)

  return (
    <nav className="nav-container">
      <a
        href="#"
        className={`nav-trigger${showNav ? " active" : ""}`}
        onClick={() => setShowNav(!showNav)}
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
      <ul
        className={`nav nav-primary${showNav ? " show" : " hide"}`}
        onClick={() => setShowNav(false)}
      >
        <li className="nav-primary-item">
          <Link className="nav-link" to="/">
            HOME
          </Link>
        </li>
        <li className="nav-primary-item">
          <AnchorLink
            className="nav-link"
            to="/#about-section"
            title="Our team"
            stripHash
          >
            ABOUT US
          </AnchorLink>
        </li>
        <li className="nav-primary-item">
          <AnchorLink
            className="nav-link"
            to="/#team-section"
            title="Our team"
            stripHash
          >
            OUR TEAM
          </AnchorLink>
        </li>
        <li className="nav-primary-item">
          <AnchorLink
            className="nav-link"
            to="/#partner-section"
            title="Our team"
            stripHash
          >
            PARTNERS
          </AnchorLink>
        </li>
        <li className="nav-primary-item">
          <AnchorLink
            className="nav-link"
            to="/#benefit-section"
            title="Our team"
            stripHash
          >
            MEMBER BENEFITS
          </AnchorLink>
        </li>
        {/* <li className="nav-primary-item"><AnchorLink className="nav-link" to="/#news-section" title="Our team" stripHash>NEWSLETTERS</AnchorLink></li> */}
        <li className="nav-primary-item">
          <Link className="nav-link" to="/testimonial/">
            Testimonials
          </Link>
        </li>
      </ul>
      <ul className="nav">
        <Link
          to="./#contactform-section"
          className="button button-outline button-white"
        >
          CONTACT US
        </Link>
      </ul>
    </nav>
  )
}

export default Header
